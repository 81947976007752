<template>
  <div class="py-8">
    <LayoutPublicHeader :isDark="true" />
    <div class="w-full">
      <div class="max-w-6xl mx-auto">
        <div class="flex justify-between items-center">
          <a-button
            @click="() => $router.go(-1)"
            class="md:ml-3 border-0 hover:bg-gray-400 bg-gray-500 text-white"
            size="large"
          >
            <template #icon> <ArrowLeftOutlined class="text-white" /></template>
            Atrás
          </a-button>

          <h1 class="text-center text-xl mx-auto font-bold my-4">LISTA DE PAQUETES</h1>

          <div></div>

          <!-- <a-form-item label="PERIODO" class="m-0 p-0">
            <a-select @select="onSelectYear" v-model:value="yearEvaluacion">
              <a-select-option v-for="y in years" :value="y" :key="y">
                {{ y }}
              </a-select-option>
            </a-select>
          </a-form-item> -->
        </div>

        <div class="w-full text-base px-6">
          <p class="my-2">
            ¡El paquete integrado de atenciones en salud para gestantes y niños, es prioritario en
            la <span class="text-red-500 font-semibold">lucha contra la Anemia!</span>
          </p>

          <p class="my-2">
            Con este paquete integrado de servicios se busca lograr que niños y niñas alcancen un
            buen estado de salud y nutrición, tengan pensamiento crítico, sean comunicacionalmente
            efectivos y con iniciativa, y vivan una infancia feliz y libre de violencia
          </p>
        </div>

        <div class="w-full text-base px-6">
          <p class="my-2">
            El Paquete Integrado de Servicios Priorizados para las niñas y niños desde la gestación
            hasta los 5 años de edad, en adelante el “Paquete Integrado”, es el conjunto de
            servicios priorizados e identificados en el marco de los Programas Presupuestales, que
            cada gestante, niña y niño debe recibir para contribuir al logro de los resultados del
            desarrollo infantil temprano:
          </p>
          <ul>
            <li class="mb-2">
              a) Paquete de atención a la gestante: 4 exámenes auxiliares (examen completo de orina,
              hemoglobina/ hematocrito, tamizaje VIH, tamizaje sífilis) en el primer trimestre y al
              menos 4 atenciones prenatales con suplemento de hierro y ácido fólico.
            </li>
            <li>
              b) Paquete integrado de servicios para niñas y niños menores de 1 año: DNI, CRED
              completo según edad, vacunas, suplementación con hierro y dosaje de hemoglobina.
            </li>
          </ul>
        </div>

        <a-spin v-if="isLoading" class="mt-10 w-full mx-auto" tip="Cargando..." />

        <div v-if="!isLoading" class="grid grid-cols-1 lg:grid-cols-2 p-4 gap-x-4 gap-y-10">
          <div v-for="grupo in grupos" :key="grupo.idgrupo">
            <CardPaquete
              goTo="/ind/"
              :id-grupo="grupo.idgrupo"
              :bg-color="grupo.bgcolor"
              :indicadores="grupo.indicadores"
              :nombre-grupo="grupo.nombre_grupo"
              :color-flecha="grupo.colorFlecha"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutPublicHeader from "@/layout/LayoutPublic/LayoutPublicHeader.vue";
import IndicadorApi from "@/api/indicador";
import { groupByPaquete } from "@/utils";
import { onMounted, ref } from "vue";
import { ArrowLeftOutlined } from "@ant-design/icons-vue";
import CardPaquete from "@/components/CardPaquete.vue";
import moment from "moment";

export default {
  components: {
    CardPaquete,
    LayoutPublicHeader,
    // Indicador
    ArrowLeftOutlined
  },
  setup() {
    const grupos = ref(null);
    const isLoading = ref(false);
    const anios = ref([]);

    var anio = moment(new Date()).local().format("YYYY");

    var oldanios = [];
    for (let index = 0; index < 4; index++) {
      oldanios.push(anio - index);
    }

    anios.value = oldanios;

    const years = ref(oldanios);
    const yearEvaluacion = ref(years.value[1]);

    const fetchIndicadores = () => {
      isLoading.value = true;
      IndicadorApi.getWithGroup(yearEvaluacion.value)
        .then(async (res) => {
          grupos.value = await groupByPaquete(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    const onSelectYear = () => {
      fetchIndicadores();
    };

    onMounted(() => {
      fetchIndicadores();
    });

    return {
      grupos,
      isLoading,
      onSelectYear,
      yearEvaluacion,
      years: anios
    };
  }
};
</script>

<style></style>
